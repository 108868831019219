html {
  font-weight: 100;
  font-size: 14px;
}

// body {
//   overflow-x: hidden;
// }

//from FlatBlue
body {
  overflow-x: hidden;
  color: $text-color;
  background-attachment: fixed, fixed;

  @include background-radial-gradient(
    ellipse,
    farthest-side,
    10%,
    0,
    $bg-gradient-color-1 20%,
    $bg-gradient-color-2
  );
}

// html,
// body,
// .app {
//   height: 100%;
// }

html,
body {
  height: 100%;
  position: relative;
}

a {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
}

th {
  font-weight: 600;
}

label {
  font-weight: 600;
}
