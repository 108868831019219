@import '../../../styles/app';

.GPA {
  margin: 0px auto;
  padding: 20px;
  width: 120px;
  border-radius: $border-radius;
  background-color: #e1e1e1;
  border: 2px solid #999999;
  color: #999999;
}

.GPA h1 {
  color: #666666;
  font-weight: bold;
}
