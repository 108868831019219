/**
 * Custom application mixins available through out the app
 */

// define sass mixin
// Generates single property classes
// -------------------------
@mixin property-variants($base, $property, $variants: ()) {
  @each $size, $value in $variants {
    @if $size == 'md' {
      #{$base} {
        #{$property}: $value;
      }

      #{$base}-n {
        #{$property}: $value;
      }
    }

    #{$base}-#{$size} {
      #{$property}: $value;
    }

    #{$base}-n-#{$size} {
      #{$property}: -$value;
    }
  }
}

//from FlatBlue
// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
  -moz-box-sizing: $boxmodel;
  box-sizing: $boxmodel;
}

//from FlatBlue
@mixin background-radial-gradient($figure, $type, $position_x, $position_y, $color_1, $color_2) {
  //there is a built-in compass radial-gradient function, but it does not provide enough compatibility, so need to imitate it
  //it also has an option $experimental-support-for-svg which should generate correct svg for IE, but it works incorrect, so
  //fixing it
  //issue id - https://github.com/chriseppstein/compass/issues/1225
  background-color: $color_1;
  //final w3c
  background-image: url(../images/bg-pattern.svg),
    radial-gradient($type $figure at $position_x $position_y, $color_1, $color_2);
  //webkit-specific
  background-image: url(../images/bg-pattern.svg),
    -webkit-radial-gradient($position_x $position_y, $type $figure, $color_1, $color_2);

  //moz-specific
  background-image: url(../images/bg-pattern.svg),
    -moz-radial-gradient($position_x $position_y, $type $figure, $color_1, $color_2);
  background-size: cover;
}
