// Container

.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

h2 {
  color: #ffffff;
  font-weight: bold;
  margin: 0 0 20px 0;
  padding: 0;
}

// Navbar

// .navbar {
//   border: none;
//   height: $navbar-height;
//   border-radius: $navbar-border-radius;
//   margin-bottom: $navbar-margin-bottom;
//   padding: 0.5rem 2.25rem;
// }

// @media (min-width: $grid-float-breakpoint) {
//   .navbar-right {
//     .dropdown-menu {
//       left: 0;
//     }
//   }
// }

// // Nav

// .nav {
//   &.nav-tabs {
//     .nav-item {
//       a {
//         color: $text-color;

//         &.active {
//           background-color: theme-color('danger');
//           border-color: theme-color('danger');
//           color: white;
//         }
//       }
//     }
//   }
// }

// Progress

.progress {
  background: rgba(black, 0.15);
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: $form-group-margin-bottom;
}

.progress-bar {
  @include box-shadow(none);
}

.progress-sm {
  height: 1rem;
  margin-bottom: 1rem;
}

.progress-xs {
  height: 5px;
  margin-bottom: 0.5rem;
}

.back {
  float: right;
}

// Breadcrumb

// .breadcrumb {
//   margin-top: -15px;
//   font-size: $font-size-small;

//   > .active {
//     font-weight: 600;
//   }

//   > li + li::before {
//     color: $gray-light;
//   }
// }

.breadcrumb {
  a {
    margin: 0;
    padding: 0;
    color: $breadcrumb-color;
    text-decoration: none;

    &:hover {
      color: $breadcrumb-active-color;
    }
  }

  .content > & {
    margin: 11px 0 9px 0;
    padding: 0;
  }
}

/*          Crumbs         */

.crumbs {
  margin: 0;
  padding: 0;
  color: $gray-300;
}

.crumbs a {
  color: $gray-300;
}

.crumbs a:focus,
.crumbs a:hover {
  color: $gray-100;
}

.crumbs .active {
  color: $gray-100;
}

// Form

.form-control {
  font-size: $font-size-base;

  @include box-shadow(none);
  @include transition(border-color ease-in-out 0.15s, background-color ease-in-out 0.15s);

  &:focus {
    @include box-shadow(none);
  }

  &.no-border {
    border: none;
    background-color: darken($input-bg, 5%);

    &:focus {
      background-color: darken($input-bg, 7%);
    }
  }
}

.input-sm {
  font-size: 13px;
}

// Buttons

.btn-inverse {
  @include button-variant($btn-inverse-color, $btn-inverse-bg, $btn-inverse-border);
}

.btn-success,
.btn-info,
.btn-warning {
  color: $white;

  &:hover {
    color: $white;
  }
}

// Alerts

.alert {
  font-size: $font-size-small;

  .close {
    color: rgba(#000, 0.4);
  }
}

.alert-sm {
  padding: 10px 15px;

  .close {
    font-size: 20px;
    top: 0;
    right: -8px;
  }
}

// Badge

.badge {
  font-size: 12px;

  .list-group-item > & {
    margin-top: 2px;
  }
}

// Table

.table-no-border {
  margin-left: -$table-cell-padding;
  margin-right: -$table-cell-padding;

  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    border-top: 0;
  }
}

.table-sm {
  font-size: $font-size-small;

  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    padding: 4px;
  }

  &.table-no-border {
    margin-left: -4px;
    margin-right: -4px;
  }
}

// Code

pre {
  display: block;
  padding: 6.5px;
  margin: 0 0 (1rem / 2);
  font-size: 13px;
  line-height: 1rem;
  word-break: break-all;
  word-wrap: break-word;
  color: $pre-color;
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-radius: $border-radius;

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}

// List

.list-group {
  padding: 0 1.25rem;
  background: $white;

  .list-group-item {
    border: none;
    padding: 1.25rem 0;
    color: theme-color('info');
    border-top: 1px solid $gray-200;

    &:hover {
      z-index: 0;
    }

    &:first-child {
      border-top: none;
    }
  }
}
