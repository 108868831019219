/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100..900&display=swap');
@import '../../node_modules/bootstrap/scss/functions';
@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/font-awesome/css/font-awesome.min.css';
@import 'mixins';
@import 'overrides';
@import 'general';
@import 'utils';
