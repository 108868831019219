@import '../../styles/app';

.btn-form {
  margin: 5px;
}

.btn-previous {
  background-color: $gray-500;
  border-color: $gray-500;
}

.dateTextbox {
  margin: -5px 0 0 0;
}

.optionText {
  margin: 6px 0 0 0;
  font-size: 1.1rem;
}

.progress-title {
  color: $text-muted;
}

.submitBlock {
  background-color: $bg-addition;
  margin: $spacer 0 0;
  padding: $spacer * 1.5 $spacer * 2;
}

.subQ-buttons {
  width: 40px;
}

.textbox-width {
  margin: 0px auto;
  width: 60px;
}

.gpa-textbox-width {
  margin: 0px auto;
  width: 100px;
}

.statisticsBadge {
  width: 3rem;
}

#root {
  label {
    font-size: 1.1rem;
    font-weight: 400;
    color: $gray-700;
  }

  label b {
    padding: 0 2px 0 0;
    font-size: 0.9rem;
    font-weight: 700;
    color: $gray-900;
  }

  textarea {
    resize: none;
  }

  .breadcrumb {
    padding: 0.75rem 0 0 0;
  }
}
