/*
 * Typography
 * ======================================================================== */
$font-size-base: 1rem;
$font-size-lg: 1.25rem !default;
$font-size-sm: 0.875rem !default;
$font-size-large: 1.1rem;
$font-size-small: 0.9rem;
$font-size-index: 0.7rem;
$font-family-base: 'Work Sans', 'HelveticaNeue-Light', sans-serif;

$line-height-computed: 1.5rem;

$font-weight-thin: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$glyphicons-halflings-include-bonus: false !default;
$glyphicons-halflings-class-prefix: glyphicon !default;
$glyphicons-halflings-font-base-size: 14px !default;

$glyphicons-halflings-font-path: '../fonts/glyphicons' !default;

$gray-100: #f8f8f8;
$gray-200: #eee;
$gray-300: #ddd;
$gray-400: #999;
$gray-500: #777;
$gray-600: #666;
$gray-700: #555;
$gray-800: #343a40;
$gray-900: #222;
$white: #fff !default;
$black: #000 !default;

$gray-light: $gray-100;
$text-muted: $gray-300 !default;

$brand-primary: #8d223e !default; // #337ab7
$brand-secondary: #beccfd !default;
$brand-success: #57b955 !default;
$brand-info: #4ebfbb !default;
$brand-warning: #f3c363 !default;
$brand-danger: #eb3349 !default;

$theme-colors: (
  primary: $brand-primary,
  secondary: $gray-700,
  success: $brand-success,
  info: $brand-info,
  warning: $brand-warning,
  danger: $brand-danger,
  light: $gray-300,
  dark: $gray-800,
  inverse: $gray-100,
  gray: $gray-400,
  default: #f8f8f8,
);

//from FlatBlue
$bg-gradient-color-1: #da566a;
$bg-gradient-color-2: #8d223e;

$text-color: $gray-700;
$link-color: #337ab7;

$state-success-text: $brand-success !default;
$state-success-bg: lighten($brand-success, 70%) !default;

$state-info-text: $brand-info !default;
$state-info-bg: lighten($brand-info, 70%) !default;

$state-warning-text: $brand-warning !default;
$state-warning-bg: lighten($brand-warning, 70%) !default;

$state-danger-text: $brand-danger !default;
$state-danger-bg: lighten($brand-danger, 70%) !default;

$alert-success-bg: #e6fff2 !default;
$alert-success-text: $state-success-text !default;
$alert-success-border: rgba($alert-success-text, 0.15) !default;

$alert-info-bg: #d2f1fb !default;
$alert-info-text: $link-color !default;
$alert-info-border: rgba($alert-info-text, 0.09) !default;

$alert-warning-bg: #fff8e6 !default;
$alert-warning-text: darken($state-warning-text, 5%) !default;
$alert-warning-border: rgba($state-warning-text, 0.2) !default;

$alert-danger-bg: #fbdcd7 !default;
$alert-danger-text: $state-danger-text !default;
$alert-danger-border: rgba($alert-danger-text, 0.09) !default;

//== Gradients
//

$logo-gradient: linear-gradient(to right, $brand-danger, #f45c43);

//== Iconography
//
$icon-font-path: '../../node_modules/bootstrap-sass/assets/fonts/bootstrap/'; // overriding bootstrap variable

/*
 * Layout
 * ======================================================================== */

$max-content-width: 1000px;

$grid-float-breakpoint: 0;

$body-bg: $gray-100;

/*
 * Media queries breakpoints
 * ======================================================================== */

$screen-xs-min: 480px; /* Extra small screen / phone */
$screen-sm-min: 768px; /* Small screen / tablet */
$screen-md-min: 992px; /* Medium screen / desktop */
$screen-lg-min: 1200px; /* Large screen / wide desktop */

//== Components
//

$content-padding-horizontal: 30px;
$content-padding-vertical: 40px;

$navbar-bg-color: #fff;
$navbar-height: 75px !default;
$navbar-border-radius: 0;
$navbar-margin-bottom: -$navbar-height !default;

$sidebar-width-open: 200px;
$sidebar-transition-time: 0.3s !default;

$sidebar-bg-color: #fff;
$sidebar-color: #4d4d4d;

// $breadcrumb-separator: '>';
// $breadcrumb-padding-horizontal: 0;
// $breadcrumb-bg: transparent;

$badge-font-weight: 600;

// $breadcrumb-divider: '>';
// $breadcrumb-padding-y: 0;
// $breadcrumb-padding-x: 0;
// $breadcrumb-bg: transparent;

//from FlatBlue
$breadcrumb-color: $text-color !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-divider-color: $text-color !default;
$breadcrumb-active-color: darken($breadcrumb-divider-color, 25%) !default;
$breadcrumb-divider: '>';

$widget-bg-common: #ffffff !default;
$widget-bg: rgba($widget-bg-common, 0.9);
$widget-title-color: #444;
$widget-padding-y: 15px;
$widget-padding-x: 20px;

//fron FlatBlue
$widget-mb: 30px;
$widget-color: $text-color;
$widget-padding: $widget-padding-y $widget-padding-x;

//from FlatBlue
$text-transparent: rgba($widget-bg-common, 0.5);
$bg-addition: rgba($widget-bg-common, 0.7);

$btn-inverse-color: #fff;
$btn-inverse-bg: $gray-700;
$btn-inverse-border: #414749;

$input-height-small: 2rem;

$enable-rounded: false;

$border-radius-base: 3px !default;

$feed-item-bg: $widget-bg;
$feed-item-color: $white;
$feed-item-line-color: transparent;

//from FlatBlue
// $basic-border-color: transparent;
// $page-title-color: $white;
$header-dropdown-bg: rgba($widget-bg-common, 0.85);
$header-dropdown-item-hover: #f8f8f8;
// $nav-link-padding-x: 0.9rem;
// $navbar-padding-x: 2rem;
// $navbar-padding-y: 0.9rem;
// $nav-tabs-active-item-border: $basic-border-color;
// $nav-tabs-active-item-border-bottom: $basic-border-color;
// $nav-tabs-border: none;
// $nav-tabs-tab-content-bg: $bg-addition;
// $nav-tabs-link-bg: $widget-bg !default;
// $tabs-widget-active-item-hover-bg: rgba(darken($widget-bg-common, 10%), 0.4);
// $border-radius-base: 3px !default;
// $feed-item-bg: $widget-bg;
// $feed-item-color: $white;
// $feed-item-line-color: transparent;
